<template>
  <div class="flex flex-col gap-8 md:mx-44 m-8">
    <!-- <BreadcrumbFAQ /> -->
    <div class="bg-white border border-gray-300 md:px-20 p-10">
      <div
        class="grid md:grid-cols-2 grid-cols-1 gap-y-8 gap-x-16 justify-center items-center"
      >
        <div
          class="flex flex-col justify-center items-center gap-4 border border-gray-300 rounded-md py-12 px-5 transform hover:scale-125 transition cursor-pointer"
          @click="$router.push({ name: 'AccountFAQ' })"
        >
          <font-awesome-icon
            icon="fa-solid fa-user"
            class="h-6 w-6 text-green-1"
          />
          <p>Account</p>
        </div>
        <div
          class="flex flex-col justify-center items-center gap-4 border border-gray-300 rounded-md py-12 px-5 transform hover:scale-125 transition cursor-pointer"
          @click="$router.push({ name: 'TripsFAQ' })"
        >
          <font-awesome-icon
            icon="fa-solid fa-car"
            class="h-6 w-6 text-green-1"
          />
          <p>Trips</p>
        </div>
        <div
          class="flex flex-col justify-center items-center gap-4 border border-gray-300 rounded-md py-12 px-5 transform hover:scale-125 transition cursor-pointer"
          @click="$router.push({ name: 'TripwepayFAQ' })"
        >
          <font-awesome-icon
            icon="fa-regular fa-credit-card"
            class="h-6 w-6 text-green-1"
          />
          <p>Tripwepay</p>
        </div>
        <div
          class="flex flex-col justify-center items-center gap-4 border border-gray-300 rounded-md py-12 px-5 transform hover:scale-125 transition cursor-pointer"
          @click="$router.push({ name: 'GeneralInfoFAQ' })"
        >
          <font-awesome-icon
            icon="fa-solid fa-gear"
            class="h-6 w-6 text-green-1"
          />
          <p>General Info</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import BreadcrumbFAQ from "../../../components/BreadcrumbFAQ.vue";

export default {
  name: "FAQUserIndex",
  // components: { BreadcrumbFAQ },
};
</script>
